import React, { useEffect, useRef, useState } from "react";
import { useIsInViewport } from "./isInViewport";

const TableRow = () => {
  const random = Math.random();
  return (
    <tr>
      <td>{random > 0.001 ? "Hans" : "Erika"}</td>
      <td>Germany</td>
    </tr>
  );
};

function App() {
  const [amountOfRows, setAmountOfRows] = useState(1000);
  const loadingRef = useRef<HTMLDivElement>(null);

  const shouldLoadMore = useIsInViewport(loadingRef);

  useEffect(() => {
    if (!shouldLoadMore) return;

    setTimeout(() => {
      setAmountOfRows(amountOfRows + 200);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadMore]);

  const tableContent = new Array(amountOfRows).fill("yo");

  const handleScroll = (e: React.UIEvent<HTMLTableElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight;

    console.log(bottom);
    if (bottom) {
      setAmountOfRows(amountOfRows + 1000);
    }
  };

  return (
    <>
      <table onScroll={handleScroll}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Addresse</th>
          </tr>
        </thead>
        <tbody>
          {tableContent.map((_, i) => (
            <TableRow key={i} />
          ))}
        </tbody>
      </table>
      <div ref={loadingRef}>Generuji víc němců</div>
    </>
  );
}

export default App;
